import React from 'react';



class KeyBio extends React.Component{
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        let props = JSON.parse(JSON.stringify(this.props));
        delete props.name
        this.setState({props: props})
    }

    onKeyDown = (e) =>{
        let keyCode = e.keyCode
        let time = this.state.time
        let keyBio = this.state.keyBio
        keyBio = keyBio === undefined ? [] : keyBio

        if(keyCode !== 9) {
            if (this.props.content === undefined) {
                time = undefined
                keyBio = []
            }


            if (time === undefined) {
                time = new Date().getTime()
                keyBio.push({
                    keyCode,
                    startTime: 0,
                    endTime: undefined
                })
            } else {
                if (keyBio.filter(single => single.keyCode === keyCode && single.endTime === undefined).length === 0) {
                    keyBio.push({
                        keyCode,
                        startTime: new Date().getTime() - time,
                        endTime: undefined
                    })
                }
            }


            this.setState({
                keyBio,
                time
            })
        }
    }

    onKeyUp = (e) =>{
        let keyCode = e.keyCode
        let time = this.state.time
        let keyBio = this.state.keyBio
        let content = e.target.value
        if(keyCode !== 9) {
            //reset time if user deletes his input
            if (content === '') {
                time = undefined
                keyBio = []
            } else {
                let index = keyBio.findIndex(single => single.keyCode === keyCode && single.endTime === undefined)
                if (index > -1 && index < keyBio.length) {
                    keyBio[index].endTime = new Date().getTime() - time
                }
            }


            this.setState({
                keyBio,
                time
            })

            if (keyBio.filter(single => single.endTime === undefined).length === 0) {
                let result = keyBio.map(single => `${single.startTime}-${single.endTime}`).join("_")
                this.props.handleChange(this.props.name, result, content)
            }
        }
    }

    render() {
      return (
          <>
              <input
                  type='text'
                  onKeyDown={e => this.onKeyDown(e)}
                  onKeyUp={e => this.onKeyUp(e)}
                  {...this.state.props}
              ></input>
          </>
      );
    }
}

export default KeyBio;
