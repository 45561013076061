import React from "react";
import {NavLink} from "react-router-dom";

class Scores extends React.Component {
    /*
    constructor(props) {
        super(props);
    }
     */

    render() {
        return(
            <>
                <div>show scores</div>
                <NavLink id='button' to='/start'>
                    <button type='button' className='button primary'>
                        Start the demo
                    </button>
                </NavLink>
            </>
        )
    }
}


export default Scores