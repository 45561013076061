import KeyBio from "../KeyBio.js";
import React from 'react';
import algo from '../algorithm.js'
class FreePWD extends React.Component{
    constructor(props) {
        super(props);
        this.state = {count: 0, pwd1: {keyBio: "", value: ""}, pwd2: {keyBio: "", value: ""}, error_message: ""};
    }

    handleChange = (name, keyBio, value) =>{
        let {state} = this
        state[name] = {keyBio, value}

        if(this.state && this.state['pwd1'] && this.state['pwd2'] && this.state['pwd1'].value === this.state['pwd2'].value ){
            let result = algo(this.state['pwd1'].keyBio, this.state['pwd2'].keyBio)
            state['result'] = result
            delete state.pwd2.value
            localStorage.setItem('freePWD-3', JSON.stringify(state))
        }
        this.setState(state)
    }

    reset = () => {
        let {state} = this
        let {count} = state
        state.count = count + 1
        delete state.result
        delete state.pwd2
        document.getElementById("content").reset();
        this.setState(state)
    }

    load_data = (data) => {
        let {state} = this
        state.error_message = ""
        try {
            const converted_JSON = JSON.parse(data)
            console.log(converted_JSON)
            if(converted_JSON.hasOwnProperty("value") && converted_JSON.hasOwnProperty("keyBio")){
                state.pwd1.value = converted_JSON.value
                state.pwd1.keyBio = converted_JSON.keyBio
            } else {
                state.error_message = "Malformatted JSON"
            }
        } catch (e) {
            console.log(e.message)
            console.log(e)
            state.error_message = e.message
        }
        this.setState(state)
    }

    render() {
        console.log(this.state)
        return(
            <>
                Paste the password from someone else in the first input field and write the same word/phrase in the second one to see if your Typing DNS matches! <br/>
                Note: the password is not hidden, use a word which you are familiar with, e.g. your name or the city you life in.
                <br/>
                <label>Paste here the JSON object</label>
                <input
                    type="text"
                    width="100%"
                    name="pwd1"
                    placeholder="paste the JSON object"
                    onChange={(e) => this.load_data(e.target.value)}
                />
                <label style={{color: "red"}}>{this.state.error_message}</label>
            <form id='content'>
                <label>{this.state.pwd1.value !== '' & 'The password you need to type is'}</label>
                <label>{this.state.pwd1.value}</label>
                <label>Password:
                    <KeyBio
                        handleChange={this.handleChange}
                        name='pwd2'
                        minLength='5'
                        placeholder=""
                        width="100%"
                        content={this.state.pwd2}
                    />
                </label>
<br/>
                Result of the algorithm: {this.state.result ? "accepted" : "rejected"}<br/>
                <button type='button' className='button secondary' onClick={this.reset}>
                    reset
                </button>
            </form></>
        );
    }
}

export default FreePWD;
