const mean = (array) => {
    return array.reduce((acc, obj) => acc + obj, 0)/array.length
}

const meanSquared = (array) => {
    const meanValue = mean(array)
    return array.reduce((acc, obj) => acc + (obj - meanValue) * (obj - meanValue), 0)/array.length
}

const standartDividation = (array) => {
    const meanSquaredValue = meanSquared(array)
    return Math.sqrt(meanSquaredValue)
}

const algo = (array, value) => {
    console.log(array)
    const means = []
    const standartDividations = []


    const arrays = array
        .map(elem => elem.split("_"))
        .map(list => {
            return list
                .map(elem => {
                    const start = elem.split("-")[0]
                    const end = elem.split("-")[1]
                    return parseInt(end) - parseInt(start)
                })
        })

    for(let j = 0;j<arrays[0].length;++j) {
        let tmp = []
        for(let i = 0;i<arrays.length;++i){
            tmp.push(arrays[i][j])
        }
        means.push(mean(tmp))
        standartDividations.push(standartDividation(tmp))
    }

    value = value
        .split("_")
        .map(elem => {
            const start = elem.split("-")[0]
            const end = elem.split("-")[1]
            return parseInt(end) - parseInt(start)
        })

    console.log(means)
    console.log(standartDividations)

    console.log(value)

    for(let index = 0;index < value.length;index++){
        const password_key = value[index]
        const mean_key = means[index]
        const standartDividations_key = standartDividations[index] + 15

        console.log(index, password_key, mean_key, standartDividations_key)

        if(password_key < (mean_key - standartDividations_key)){
            console.log("Too small")
            return false
        }

        if(password_key > (mean_key + standartDividations_key)){
            console.log("Too large")
            return false
        }
    }

    return true
}

export default algo