import KeyBio from "../KeyBio.js";
import React from 'react';
import algo from '../algorithm.js'
import {NavLink} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
class FreePWD extends React.Component{
    constructor(props) {
        super(props);
        this.state = {count: 0, pwd1: {keyBio: "", value: ""}};
    }

    handleChange = (name, keyBio, value) =>{
        let {state} = this
        state[name] = {keyBio, value}

        if(this.state && this.state['pwd1'] && this.state['pwd2'] && this.state['pwd1'].value === this.state['pwd2'].value ){
            let result = algo(this.state['pwd1'].keyBio, this.state['pwd2'].keyBio)
            state['result'] = result
            delete state.pwd1.value
            delete state.pwd2.value
            localStorage.setItem('freePWD-2', JSON.stringify(state))
        }
        this.setState(state)
    }

    reset = () => {
        let {state} = this
        let {count} = state
        state.count = count + 1
        delete state.result
        delete state.pwd1
        document.getElementById("content").reset();
        this.setState(state)
    }

    render() {
        return(
            <form id='content'>
                Type a password which you can later share with someone else to see if the Typing DNS of you matches! <br/>
                Note: the password is not hidden, use a word which you are familiar with, e.g. your name or the city you life in.
                <label>Password:
                    <KeyBio
                        handleChange={this.handleChange}
                        name='pwd1'
                        minLength='5'
                        placeholder=""
                        width="100%"
                        content={this.state.pwd1}
                    />
                </label>
                <button type='button' className='button secondary' onClick={this.reset}>
                    reset
                </button>
                <br/>
                <CopyToClipboard
                    text={JSON.stringify(this.state.pwd1)}
                >
                    <button type='button' className='button primary'>
                        Copy to clipboard!
                    </button>
                </CopyToClipboard>
                <br/>
                <NavLink id='button' to='/paste-password'>
                    <button type='button' className='button primary'>
                        Go to the next section!
                    </button>
                </NavLink>
            </form>
        );
    }
}

export default FreePWD;
