import ScrollContainer from './style/ScrollContainer.js';
import {  Route, Switch } from 'react-router-dom';
import Nav from './routes_old/Nav.js'
import HomeShowCase from "./routes/HomeShowCase.js"
import RecordPassword from "./routes/RecordPassword.js";
import PastePassword from "./routes/PastePassword.js";
import Scores from "./routes/Scores.js"

function App() {
    return (
        <div id='app'>
            <ScrollContainer>

                <Route path="*" component={Nav} />
                <Switch>
                    <Route exact path="/" component={Scores}/>
                    <Route exact path="/start" component={HomeShowCase}/>
                    <Route exact path="/record-password" component={RecordPassword}/>
                    <Route exact path="/paste-password" component={PastePassword}/>
                    {/*
                    <Route exact path='/' component={Home}></Route>
                    <Route exact path='/q/0' component={Instructions}></Route>
                    <Route exact path='/q/1' component={FreePWD}></Route>
                    <Route exact path='/q/2' component={TypeWords}></Route>
                    <Route exact path='/q/3' component={BeatMine}></Route>
                    <Route exact path='/q/4' component={Finish}></Route>
                    <Route exact path='/end' component={End}></Route>
                    <Route path="*" component={Notfound} />
                    */}
                </Switch>
            </ScrollContainer>
        </div>
    );
}

export default App;