const convertStringToArray = (string) => {
    let array = string
        .split('_')
        .map(single => single.split('-'))
        .map(single => [parseInt(single[0]), parseInt(single[1])])

    return array
}

const simpleThreshHold = (baseArray, checkArray, time, threshold) => {
    let down = baseArray
        .map((single, index) => Math.abs(single[0] - checkArray[index][0]))
        .reduce((accumulator, currentValue) => accumulator + currentValue/(baseArray.length - 1))
    let up = baseArray
        .map((single, index) => Math.abs(single[1] - checkArray[index][1]))
        .reduce((accumulator, currentValue) => accumulator + currentValue/(baseArray.length))
    let center = baseArray
        .map((single, index) => Math.abs((single[1]-single[0]) - (checkArray[index][1] - checkArray[index][0])))
        .reduce((accumulator, currentValue) => accumulator + currentValue/(baseArray.length))



    //console.log(down, center, up)
    let result = Math.floor((down + up + center) / 3)
    //console.log(result)
    if(time){
        return result
    }
    return result < threshold
}

const algo = (baseline, toCheck, time = false, threshold = 50) => {

    let baseArray = convertStringToArray(baseline)
    let checkArray = convertStringToArray(toCheck)

    if(baseArray.length !== checkArray.length){
        return false
    }

    //console.table(baseArray.map((single, index) => [single.join('-'), checkArray[index].join("-")]))


    return simpleThreshHold(baseArray, checkArray, time, threshold)
}

export default algo