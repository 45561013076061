import KeyBio from "../KeyBio.js";
import React from 'react';
import algo from '../algorithm.js'
import improved_algo from "../algorithm_new.js"
import {NavLink} from "react-router-dom";

class FreePWD extends React.Component{
    constructor(props) {
        super(props);
        ///*
        this.state = {
            count: 0,
            pwd1: {keyBio: "", value: ""},
            pwd2: {keyBio: "", value: ""},
            pwd3: {keyBio: "", value: ""},
            pwd4: {keyBio: "", value: ""}
        };
        /*

        this.state = {
            count: 0,
            pwd1: {keyBio: "0-111_199-310_317-406_421-605_592-679_702-790", value: "moritz"},
            pwd2: {keyBio: "0-111_231-366_381-477_525-672_663-758_805-886", value: "moritz"},
            pwd3: {keyBio: "0-85_214-367_374-445_501-661_640-718_773-861", value: "moritz"},
            pwd4: {keyBio: "", value: ""}
        }

         */
    }

    handleChange = (name, keyBio, value) =>{
        let {state} = this
        state[name] = {keyBio, value}

        if(this.state && this.state['pwd1'] && this.state['pwd2'] && this.state['pwd3'] && this.state['pwd4'] && this.state['pwd1'].value === this.state['pwd4'].value ){
            let result = improved_algo([this.state['pwd1'].keyBio, this.state['pwd2'].keyBio, this.state['pwd3'].keyBio], this.state['pwd4'].keyBio)
            state['result'] = result
            localStorage.setItem('freePWD-1', JSON.stringify(state))
        } else {
            state["result"] = false
        }
        this.setState(state)
    }

    comparePasswords = (data_1, data_2) => {
        if(!data_1.hasOwnProperty("value") || !data_2.hasOwnProperty("value")){
            return ""
        }

        if(data_1.value.split("").length === 0 || data_2.value.split("").length === 0){
            return ""
        }

        if(!(data_1.value.split("").length === data_2.value.split("").length)){
            return "Passwords do not match in length! Finish typing or re-type the password."
        }

        if(data_1.value !== data_2.value){
            return "Passwords match in length but not in value! Re-type the password"
        }

        if(algo(data_1.keyBio, data_2.keyBio, false, 100)){
            return true
        }

        return "Please, retype the above password as the first and the above passwords are typed in a speed which differs to much"
    }

    checkForLength = (data) => {
        if(!data.hasOwnProperty('value')){
            return true
        }
        const lengthValue = data.value.split("").length
        const lengthKeyBio = data.keyBio.split("_").length

        if(lengthValue === 0){
            return true
        }

        return lengthValue === lengthKeyBio
    }

    reset = () => {
        let {state} = this
        let {count} = state
        let newState = {
            count: count+1,
            pwd1: {keyBio: "", value: ""},
            pwd2: {keyBio: "", value: ""},
            pwd3: {keyBio: "", value: ""},
            pwd4: {keyBio: "", value: ""}
        }
        document.getElementById("content").reset();
        this.setState(newState)
    }

    render() {
        console.log(this.state.pwd4)
        return(
            <form id='content'>
                Enter first three times the same password, so that they system can create a unique patter for you. Afterwards you enter the password once more.  <br/>
                If you have mistyped delete the whole input, with that the recording is resetted. By pressing the reset button the whole form is resetted. at the <br/>
                Note: the password is not hidden, use a word which you are familiar with, e.g. your name or the city you life in. <br/>
                Second Note: only lower case, alphabetic characters work!

                <label>First time:
                    <KeyBio
                        handleChange={this.handleChange}
                        name='pwd1'
                        minLength='5'
                        placeholder=""
                        defaultValue={this.state.pwd1.value}
                        width="100%"
                        content={this.state.pwd1}
                    />
                    <div style={{color: "red"}}>{this.checkForLength(this.state.pwd1) ? '': 'Please retype the above password! The length of the password and recorded keystrokes are not matching...'}</div>
                    Second time:
                    <KeyBio
                        handleChange={this.handleChange}
                        name='pwd2'
                        minLength='5'
                        placeholder=""
                        defaultValue={this.state.pwd2.value}
                        width="100%"
                        content={this.state.pwd2}
                    />
                    <div style={{color: "red"}}>{this.checkForLength(this.state.pwd2) ? '': 'Please retype the above password! The length of the password and recorded keystrokes are not matching...'}</div>
                    {this.comparePasswords(this.state.pwd1, this.state.pwd2) === true ?
                        <div style={{color: "green"}}>The password is of high quality!</div>
                        :
                        <div style={{color: "red"}}>{this.comparePasswords(this.state.pwd1, this.state.pwd2)}</div>
                    }
                    Third time:
                    <KeyBio
                        handleChange={this.handleChange}
                        name='pwd3'
                        minLength='5'
                        placeholder=""
                        defaultValue={this.state.pwd3.value}
                        width="100%"
                        content={this.state.pwd3}
                    />
                    <div style={{color: "red"}}>{this.checkForLength(this.state.pwd3) ? '': 'Please retype the above password! The length of the password and recorded keystrokes are not matching...'}</div>
                    {this.comparePasswords(this.state.pwd1, this.state.pwd3) === true ?
                        <div style={{color: "green"}}>The password is of high quality!</div>
                        :
                        <div style={{color: "red"}}>{this.comparePasswords(this.state.pwd1, this.state.pwd3)}</div>
                    }
                </label>
                <br/>
                <br/>
                <label>Try to log in:
                    <KeyBio
                        handleChange={this.handleChange}
                        name='pwd4'
                        minLength='5'
                        placeholder=""
                        disable="true"
                        content={this.state.pwd2}
                    />
                </label>
                Result of the algorithm: {this.state.result ? "accepted" : "rejected"}
                <br/>
                Number of attempts: {this.state.count}
                <br/>
                <button type='button' className='button secondary' onClick={this.reset}>
                    reset
                </button>
                <br/>
                <NavLink id='button' to='/'>
                    <button type='button' className='button primary'>
                        Thanks for participating!
                    </button>
                </NavLink>
            </form>
        );
    }
}

export default FreePWD;
